import React from 'react'
import logo from '../../asset/img/logo/Rappi_logo.svg.png'
import imgBackground from '../../asset/img/login/background.png'
import {Container, Hidden} from '@material-ui/core'
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

const LoginTemplate = (props: Props) => {
  const {children} = props
  const classes = useStyles();

  return (
    <Container maxWidth={false} className="p-0i template-login">
      <Grid container className={classes.root} spacing={0} direction="row" justify="center" alignItems="stretch">
        <Hidden xsDown>
          <Grid item lg={9} sm={7} md={8} className="container-background">
            <img src={imgBackground} className="img-background"/>
          </Grid>
        </Hidden>

        <Grid item xs={12} lg={3} sm={5} md={4} className="main-login">
          <Grid container direction="row" justify="center">
            <Grid item>
              <img src={logo} className="logo m-t-40 m-b-70"/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={12} sm={12} md={12} className="position-relative">
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

type Props = {
  children?: any
}

export default LoginTemplate

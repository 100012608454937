import {Constants} from '../config/Constants'
import {LanguageType} from "../types/LanguageType";

export const Functions = {
  errorObject: (errors: any) => {
    let description = 'Ha ocurrido un error'

    if (
      errors.response &&
      errors.response.data &&
      errors.response.data.message
    ) {
      description = errors.response.data.message
    } else if (errors.response && errors.response.message) {
      description = errors.response.message
    } else if (errors.message) {
      description = errors.message
    }

    return description
  },

  initialLettersToUpper(string: string) {
    let letters = ''
    let count = 0
    if (string) {
      const words = string.split(' ')
      if (words.length > 1) {
        words.forEach((value: string) => {
          const letter = value.charAt(0)

          if (letter.length && letter !== '' && count <= 1) {
            letters += value.charAt(0)
            count++
          }
        })
      } else if (words.length == 1) {
        const word = words[0]
        letters = word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase()
      }
    }

    return letters.toUpperCase()
  },

  formatNumber(num: any) {
    num += ''
    const splitStr = num.split('.')
    let splitLeft = splitStr[0]
    const splitRight =
      splitStr.length > 1 ? Constants.SEP_DECIMAL + splitStr[1] : ''
    const regx = /(\d+)(\d{3})/

    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + Constants.SEPARATOR + '$2')
    }

    return splitLeft + splitRight
  },

  getLanguage(code: string): LanguageType | undefined {
    return Constants.LANGUAGES.find(item => item.code == code)
  },

  toFixed(number: any, digit = 2) {
    if (parseInt(number) == number) {
      return number
    }
    return parseFloat(number).toFixed(digit)
  }
}

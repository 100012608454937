import React, {Suspense} from 'react'
import LoginTemplate from '../../templates/login-template/LoginTemplate'
const FormSelectCompany = React.lazy(() => import("./form-select-company/FormSelectCompany"))
import Loading from "../../components/loading/Loading";

const SelectCompany = () => {
  return (
    <LoginTemplate>
      <Suspense fallback={<Loading/>}>
        <FormSelectCompany/>
      </Suspense>
    </LoginTemplate>
  )
}

export default SelectCompany

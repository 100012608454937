import React, { Suspense } from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Paths from "../config/Paths";
import ErrorBoundaryRoute from './error-boundary-route'
import Loading from "../components/loading/Loading";
import SelectCompany from "../modules/seect-company/SelectCompany"

const Login = React.lazy(() => import("../modules/login/Login"))
const PlacesTypes = React.lazy(() => import("../modules/places-types/PlacesTypes"))
const Home = React.lazy(() => import("../modules/home/Home"))
const Places = React.lazy(() => import("../modules/places/Places"))
const Zones = React.lazy(() => import("../modules/zones/Zones"))
const Auditors = React.lazy(() => import("../modules/auditors/Auditors"))
const Tasks = React.lazy(() => import("../modules/tasks/Tasks"))
const TaskMap = React.lazy(() => import("../modules/task-map/TaskMap"))
const PlaceDetail = React.lazy(() => import("../modules/place-detail/PlaceDetail"))
const ZonesStatus = React.lazy(() => import("../modules/zones-status/ZonesStatus"))
const POPMaterials = React.lazy(() => import("../modules/pop-materials/POPMaterials"))
const TaskStatus = React.lazy(() => import("../modules/task-status/TaskStatus"))

const routes = [
  {
    path: Paths.LOGIN,
    component: Login,
    roles: []
  }, {
    path: Paths.SELECT_COMPANY,
    component: SelectCompany,
    roles: []
  }, {
    path: Paths.PLACES_TYPES,
    component: PlacesTypes,
    roles: []
  }, {
    path: Paths.PLACES,
    component: Places,
    roles: []
  }, {
    path: Paths.PLACES_DETAILS,
    component: PlaceDetail,
    roles: []
  }, {
    path: Paths.ZONES_STATUS,
    component: ZonesStatus,
    roles: []
  }, {
    path: Paths.ZONES,
    component: Zones,
    roles: []
  }, {
    path: Paths.AUDITORS,
    component: Auditors,
    roles: []
  }, {
    path: Paths.TASK_STATUS,
    component: TaskStatus,
    roles: []
  }, {
    path: Paths.TASKS_MAP,
    component: TaskMap,
    roles: []
  }, {
    path: Paths.TASKS,
    component: Tasks,
    roles: []
  }, {
    path: Paths.POP_MATERIALS,
    component: POPMaterials,
    roles: []
  }, {
    path: '/',
    component: Home,
    roles: []
  }
]

const Routes = () => {
  const componentsRoutes: any[] = []
  const roles: any = []

  const renderRoutes = (routes: any[], path: string) => {
    routes.forEach((route: any) => {
      if (route.routes) {
        renderRoutes(route.routes, route.path)
      }

      const hasPermission: boolean = route.roles.find((rolRoute: string) =>
        roles.find((rolUser: string) => rolUser === rolRoute)
      )

      if (route.roles.length === 0 || hasPermission) {
        const routePath = (path + route.path).replace('//', '/')
        componentsRoutes.push(
          <ErrorBoundaryRoute
            key={componentsRoutes.length}
            path={routePath}
            component={route.component}
          />
        )
      }
    })
  }

  renderRoutes(routes, '')

  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <Switch>{componentsRoutes}</Switch>
      </Suspense>
    </Router>
  )
}

export default Routes

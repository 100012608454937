const Paths = {
  LOGIN: '/login',
  SELECT_COMPANY: '/select/company',
  PLACES_TYPES: '/places/types',
  PLACES: '/places',
  PLACES_DETAILS: '/place/detail/:id',
  ZONES: '/zones',
  ZONES_STATUS: '/zones/status/:id',
  TASK_STATUS: '/tasks/status/:id',
  AUDITORS: '/auditors',
  TASKS: '/tasks',
  TASKS_MAP: '/tasks/map/:id',
  HOME: '/',
  HELP: '/help',
  POP_MATERIALS: '/pop-materials'
}

export default Paths

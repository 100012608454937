import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {Config} from '../config/Config'

export const ErrorBoundaryRoute = (props: Props) => {
  const {component, path} = props

  if (!component) {
    return <Route exact path={path} component={component} render={() => <Redirect to={Config.PATH_DEFAULT}/>}/>
  }

  return <Route exact {...props}/>
}

type Props = {
  component: any
  path: string
}

export default ErrorBoundaryRoute

import flagEs from 'flag-icon-css/flags/4x3/es.svg'
import flagUs from 'flag-icon-css/flags/4x3/us.svg'
import flagBr from 'flag-icon-css/flags/4x3/br.svg'

export const Constants = {
  SEPARATOR: '.',
  SEP_DECIMAL: ',',
  LANGUAGE_DEFAULT: 'es',
  LANGUAGES: [
    {
      code: 'es',
      flag: 'es',
      slug: 'SPANISH',
      icon: flagEs
    }, {
      code: 'en',
      flag: 'us',
      slug: 'ENGLISH',
      icon: flagUs
    }, {
      code: 'pt',
      flag: 'br',
      slug: 'PORTUGUESE',
      icon: flagBr
    },
  ],
  TASKS_STATUSES: {
    NO_START: 1,
    IN_PROCESS: 2,
    IN_PAUSE: 3,
    FINISHED: 4,
    ELIMINATED: 5
  },
  TASKS_TYPE: {
    INVESTIGATION: 1,
    AUDIT: 2
  }
}

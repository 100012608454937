import {HIDE_DRAWER, SHOW_DRAWER} from '../constants/show-drawer'

const initialState = {
  show: false,
  placement: 'right',
  title: 'right',
  component: null,
  size: 'xs'
}

export default (state = initialState, action: any) => {
  switch (action.type) {
  case SHOW_DRAWER:
    return {
      ...state,
      show: action.payload.show,
      placement: action.payload.placement,
      component: action.payload.component,
      title: action.payload.title,
      size: action.payload.size
    }
  case HIDE_DRAWER:
    return {
      ...state,
      show: action.show,
      component: null,
      title: ''
    }
  default:
    return state
  }
}

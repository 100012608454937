const LocalStorage  = {
  setJson: (key: string, value: any) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  },

  getJson: (key: string) => {
    const item: any = window.localStorage.getItem(key)

    if(item) {
      return JSON.parse(item)
    }

    return {}
  },

  setItem: (key: string, value: string) => {
    window.localStorage.setItem(key, value)
  },

  getItem: (key: string) => {
    return window.localStorage.getItem(key)
  },

  clearAll: () => {
    window.localStorage.clear()
  },

  removeItem: (key: string) => {
    window.localStorage.removeItem(key)
  },

  getLanguageMap: () => {
    const code = window.localStorage.getItem('language')

    if (!code){
      return 'es'
    }

    return code === 'pt' ? 'pt-BR' : code
  },
}

export default LocalStorage

export const Config = {
  END_POINT: 'https://api.fgeaudit.tolber.io/api/',
  EXTERNAL_SERVICES: 'https://api.tdt-resources.dev.tolber.io/api/',
  BASE_URL: 'https://fgeaudit.tolber.io',
  PATH_DEFAULT: '/home',
  PATH_LOGIN: '/login',
  MENU: [
    {
      path: '/home',
      text: 'Home',
      icon: 'home',
      roles: [],
    }
  ],
  GOOGLE_MAPS_API_KEY: 'AIzaSyDxnQB9KuSOEfrn5BhePR_yo6mCmIDV5OY',
  GOOGLE_STYLE_ID: '8cdad8e67dca57ad',
  GOOGLE_ZOOM_INPUT_ADDRESS: 14,
  PER_PAGE: 15
}

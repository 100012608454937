import AuthenticationType from "../../types/AuthenticationType";
import {AUTHENTICATION_SET_COMPANY, AUTHENTICATION_SET_DATA} from "../constants/authentication";
import SessionStorage from '../../commons/SessionStorage'
import LocalStorage from '../../commons/LocalStorage'

export const authenticationSetData = (data: AuthenticationType) => {
  return {
    type: AUTHENTICATION_SET_DATA,
    payload: {...data},
  }
}

export const authenticationSetCompany = (companyId: string) => {
  setCompanyId(companyId)

  return {
    type: AUTHENTICATION_SET_COMPANY,
    payload: {companyId},
  }
}


export const setSession = (data: any, rememberMe: boolean) => {
  if (rememberMe) {
    LocalStorage.setItem('user_id', data.user.id)
    LocalStorage.setItem('user_name', data.user.attributes.name)
    LocalStorage.setItem('user_role', data.user.attributes.role)
    LocalStorage.setItem('user_token', data.access_token)
  } else {
    SessionStorage.setItem('user_id', data.user.id)
    SessionStorage.setItem('user_name', data.user.attributes.name)
    SessionStorage.setItem('user_role', data.user.attributes.role)
    SessionStorage.setItem('user_token', data.access_token)
  }
}

export const getSession = () => {
  let id = LocalStorage.getItem('user_id')
  let name = LocalStorage.getItem('user_name')
  let role = LocalStorage.getItem('user_role')
  let access_token = LocalStorage.getItem('user_token')

  if (id && name && role && access_token) {
    return {
      isAuthenticated: true,
      account: {
        id: parseInt(id),
        name: name,
      },
      roles: [role],
      token: access_token,
    }
  }

  id = SessionStorage.getItem('user_id')
  name = SessionStorage.getItem('user_name')
  role = SessionStorage.getItem('user_role')
  access_token = SessionStorage.getItem('user_token')

  if (id && name && role && access_token) {
    return {
      isAuthenticated: true,
      account: {
        id: parseInt(id),
        name: name,
      },
      roles: [role],
      token: access_token,
    }
  }

  return {
    isAuthenticated: false,
    account: {
      id: 0,
      name: '',
    },
    roles: [],
    token: '',
  }
}

export const clearSession = () => {
  LocalStorage.clearAll()
  SessionStorage.clearAll()
}

export const setCompanyId = (companyId: string) => {
  LocalStorage.setItem('company_id', companyId)
}

export const getCompanyId = (): any => {
  return LocalStorage.getItem('company_id')
}

import {combineReducers} from 'redux'
import authentication from './authentication'
import language from './language'
import dialogConfirm from './dialog-confirm'
import showDrawer from './show-drawer'

const reducers = {
    authentication,
    language,
    dialogConfirm,
    showDrawer
}

export default combineReducers(reducers)

import AuthenticationType from "../../types/AuthenticationType";
import ActionType from "../../types/ActionType";
import {AUTHENTICATION_SET_COMPANY, AUTHENTICATION_SET_DATA} from "../constants/authentication";
import {getSession} from "../actions/authentication";

const initialState: AuthenticationType = {
    companyId: '',
    companies: [],
    ...getSession()
}

export default (state: AuthenticationType = initialState, action: ActionType): AuthenticationType => {
    switch (action.type) {
        case AUTHENTICATION_SET_DATA:
            return {
                ...action.payload
            }
            break
        case AUTHENTICATION_SET_COMPANY:
            return {
                ...state,
                companyId: action.payload.companyId
            }
            break
        default:
            return state
    }
}

import {Functions} from '../../commons/Functions'
import {Constants} from '../../config/Constants'
import LocalStorage from '../../commons/LocalStorage'
import es from '../../i18n/es.json'
import en from '../../i18n/en.json'
import pt from '../../i18n/pt.json'
import {LANGUAGE_CHANGE} from "../constants/languages";

const params = new URLSearchParams(window.location.search)
const localCode = LocalStorage.getItem('language')
let code: any = Constants.LANGUAGE_DEFAULT
let dictionary: any = es

const getLanguageNavigator = () => {
  let code: any = Constants.LANGUAGE_DEFAULT

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const language = navigator.language || navigator.userLanguage
  if(language.indexOf('en') >= 0) {
    code = 'en'
  } else if(language.indexOf('pt') >= 0) {
    code = 'pt'
  }

  return code
}

if(params.has('lang')) {
  switch (params.get('lang')) {
  case 'es':
  case 'en':
  case 'pt':
    code = params.get('lang')
    break
  default:
    code = getLanguageNavigator()
  }

  LocalStorage.setItem('language', code)
} else if(localCode) {
  code = localCode
} else {
  code = getLanguageNavigator()

  LocalStorage.setItem('language', code)
}

switch (code) {
case 'en':
  dictionary = en
  break
case 'pt':
  dictionary = pt
  break
default:
  dictionary = es
  break
}

const initialState = {
  ...Functions.getLanguage(code),
  dictionary: dictionary
}

export default (state = initialState, action: any) => {
  switch (action.type) {
  case LANGUAGE_CHANGE:
    return {
      ...state,
      ...action.traducciones.language,
      dictionary: action.traducciones.dictionary
    }
  default:
    return state
  }
}

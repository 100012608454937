import ActionType from '../../types/ActionType'
import DialogConfirmType from '../../types/DialogConfirmType'
import {
  DIALOG_CONFIRM_HIDE,
  DIALOG_CONFIRM_RESET,
  DIALOG_CONFIRM_SHOW,
  DIALOG_CONFIRM_SPINNER
} from '../constants/dialog-confirm'

const initialState = {
  showDialogConfirm: false,
  title: '',
  description: '',
  handleConfirm: null,
  spinner: false,
}

export default (state = initialState, action: ActionType): DialogConfirmType => {
  switch (action.type) {
  case DIALOG_CONFIRM_SHOW:
    return {
      ...state,
      showDialogConfirm: true,
      title: action.payload.title,
      description: action.payload.description,
      handleConfirm: action.payload.handleConfirm,
    }
  case DIALOG_CONFIRM_HIDE:
    return {
      ...state,
      showDialogConfirm: false
    }
  case DIALOG_CONFIRM_SPINNER:
    return {
      ...state,
      spinner: action.payload.spinner
    }
  case DIALOG_CONFIRM_RESET:
    return {
      ...initialState
    }
  default:
    return state
  }
}

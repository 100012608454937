import React from 'react'
import ReactDOM from 'react-dom'
import './asset/scss/style.scss'
import App from './App'
import {Provider} from 'react-redux'
import Store from './redux/store/Store'

ReactDOM.render(
  <Provider store={Store}>
    <App/>
  </Provider>,
  document.getElementById('root')
)
